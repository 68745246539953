export const guestLinks = [
  { text: 'Home', href: '' },
  { text: 'Discussion topics', href: '#Discussiontopics' },
  { text: 'Create-Discussion topics', href: '#CreateDiscussiontopics' },
];

export const userLinks = [
  { text: 'Home', href: '' },
  { text: 'Discussion topics', href: '#Discussiontopics' },
  { text: 'Create-Discussion topics', href: '#CreateDiscussiontopics' },
  { text: 'Profile', href: '#profile' },
  { text: 'Settings', href: '#settings' },
];
